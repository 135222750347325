const features = [
  {
    id: 1,
    icon: 'motherland.jpg',
    title: 'Отечественное производство',
    text: 'Вся наша продукция производится на отечественном оборудовании и по нашим собственным технологиям'
  },
  {
    id: 2,
    icon: 'science.jpg',
    title: 'Передовая наука',
    text: 'Наши научные открытия двигают человечество вперёд'
  },
  {
    id: 3,
    icon: 'eco.jpg',
    title: 'Экологичность',
    text: 'Наше производство полностью экологичное и безотходное'
  },
  {
    id: 4,
    icon: 'delivery.jpg',
    title: 'Быстрая доставка',
    text: 'Доставка по всему миру'
  },
  {
    id: 5,
    icon: 'time.jpg',
    title: 'Четкое соблюдение сроков',
    text: 'Мы гарантируем чёткое соблюдение всех сроков'
  },
  {
    id: 6,
    icon: 'hand.jpg',
    title: 'Круглосуточная поддержка',
    text: 'Мы поможем ровно в тот момент, когда вам это нужно'
  }
];

export default features;
