import { render, staticRenderFns } from "./SimpleCarousel.vue?vue&type=template&id=06426b3c&scoped=true&"
import script from "./SimpleCarousel.vue?vue&type=script&lang=js&"
export * from "./SimpleCarousel.vue?vue&type=script&lang=js&"
import style0 from "./SimpleCarousel.vue?vue&type=style&index=0&id=06426b3c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06426b3c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/av/git/landing/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06426b3c')) {
      api.createRecord('06426b3c', component.options)
    } else {
      api.reload('06426b3c', component.options)
    }
    module.hot.accept("./SimpleCarousel.vue?vue&type=template&id=06426b3c&scoped=true&", function () {
      api.rerender('06426b3c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SimpleCarousel.vue"
export default component.exports