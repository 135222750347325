var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "carousel" }, [
    _c(
      "ul",
      {
        staticClass: "carousel__list",
        style: { transform: _vm.transform },
        on: {
          touchstart: _vm.startSwipe,
          touchmove: _vm.swipe,
          touchend: _vm.endSwipe,
        },
      },
      _vm._l(_vm.items, function (item) {
        return _c("li", { key: item.id, staticClass: "carousel__list-item" }, [
          _c("div", { staticClass: "carousel__info" }, [
            _c("h2", { staticClass: "carousel__title" }, [
              _vm._v("\n          " + _vm._s(item.name) + "\n        "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "carousel__text" }, [
              _vm._v("\n          " + _vm._s(item.description) + "\n        "),
            ]),
          ]),
          _vm._v(" "),
          _c("img", {
            staticClass: "carousel__image",
            attrs: { src: _vm.images[`./team/${item.image}`] },
          }),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "carousel__dots" },
      _vm._l(_vm.items, function (item) {
        return _c("li", {
          key: item.id,
          class: _vm.setDotClass(item.id),
          on: {
            click: function ($event) {
              return _vm.selectDot(item.id)
            },
          },
        })
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "carousel__button carousel__button_left",
        on: { click: _vm.prevSlide },
      },
      [
        _c("svg-icon", {
          attrs: {
            "icon-name": "arrow-left-icon",
            "icon-class": "icon carousel__icon-button",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "carousel__button carousel__button_right",
        on: { click: _vm.nextSlide },
      },
      [
        _c("svg-icon", {
          attrs: {
            "icon-name": "arrow-right-icon",
            "icon-class": "icon carousel__icon-button",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }