var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main" },
    [
      _c("about-section"),
      _vm._v(" "),
      _c("features-section"),
      _vm._v(" "),
      _c("projects-section"),
      _vm._v(" "),
      _c("team-section"),
      _vm._v(" "),
      _c("contacts-section"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }