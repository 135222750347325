var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-section",
    { attrs: { "class-name": "team", "section-title": _vm.pageTitle } },
    [_c("simple-carousel", { attrs: { items: _vm.team } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }