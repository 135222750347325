var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-section",
    { attrs: { "class-name": "about" } },
    [
      _c("div", { staticClass: "about__container" }, [
        _c("h1", { staticClass: "about__title" }, [
          _vm._v("Меняем жизнь человечества к лучшему"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "about__text" }, [
          _vm._v(
            "\n      Всего один шаг отделяет нас от удивительного и невероятного эволюционного скачка, который\n      полностью изменит весь мир, столь привычный нам с рождения!\n    "
          ),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "about__button",
            attrs: {
              name: "about-button",
              type: "button",
              "aria-label": "Find out more",
            },
            on: { click: _vm.togglePopup },
          },
          [_vm._v("\n      Узнать больше\n    ")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "the-popup",
        { attrs: { "is-open": _vm.isOpen, "toggle-popup": _vm.togglePopup } },
        [_c("youtube-container", { attrs: { "video-id": "0UBiw1xkHl8" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }