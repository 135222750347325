const links = [
  {
    id: 1,
    url: 'features',
    anchor: 'Преимущества'
  },
  {
    id: 2,
    url: 'projects',
    anchor: 'Проекты'
  },
  {
    id: 3,
    url: 'team',
    anchor: 'Команда'
  },
  {
    id: 4,
    url: 'contacts',
    anchor: 'Контакты'
  }
];

export default links;
