var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "popup-fade" } }, [
    _vm.isOpen
      ? _c("div", { staticClass: "popup" }, [
          _c("div", {
            staticClass: "popup__overlay",
            on: { click: _vm.togglePopup },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "popup__container" },
            [
              _c("button", {
                staticClass: "popup__button-close",
                on: { click: _vm.togglePopup },
              }),
              _vm._v(" "),
              _vm._t("default"),
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }