import { render, staticRenderFns } from "./TheFooter.vue?vue&type=template&id=dd042928&scoped=true&"
import script from "./TheFooter.vue?vue&type=script&lang=js&"
export * from "./TheFooter.vue?vue&type=script&lang=js&"
import style0 from "./TheFooter.vue?vue&type=style&index=0&id=dd042928&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd042928",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/av/git/landing/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dd042928')) {
      api.createRecord('dd042928', component.options)
    } else {
      api.reload('dd042928', component.options)
    }
    module.hot.accept("./TheFooter.vue?vue&type=template&id=dd042928&scoped=true&", function () {
      api.rerender('dd042928', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/TheFooter.vue"
export default component.exports