const team = [
  {
    id: 1,
    image: 'nechayev.jpg',
    name: 'Сергей Нечаев',
    description: `Офицер войск специального назначения, разведки по особым поручениям 
      и специалист по урегулированию сложных ситуаций любыми, в том числе силовыми методами.`
  },
  {
    id: 2,
    image: 'sechenov.jpg',
    name: 'Дмитрий Сеченов',
    description: `Министр промышленности и глава Предприятия № 3826, 
    выдающийся учёный своего времени, советский футурист, 
      нейрохирург, робототехник, один из создателей нейросети «Коллектив».`
  },
  {
    id: 3,
    image: 'filatova.jpg',
    name: 'Лариса Филатова',
    description: 'Высококлассный нейрохирург и талантливая сотрудница Академии Последствий.'
  },
  {
    id: 4,
    image: 'baba-zina.jpg',
    name: 'Зинаида Муравьёва',
    description: 'Подполковник, бывший офицер связи Предприятия № 3826.'
  },
  {
    id: 5,
    image: 'shtok.jpg',
    name: 'Михаэль Штокхаузен',
    description:
      'Немецкий учёный, который после войны устремился в СССР в поисках знаний и возможностей.'
  }
];

export default team;
