var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { staticClass: "header", class: { header_fixed: _vm.isScrolled } },
    [
      _c(
        "div",
        {
          staticClass: "header__container",
          class: { header__container_fixed: _vm.isScrolled },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("nav", { staticClass: "header__navigation" }, [
            _c(
              "button",
              {
                staticClass: "header__button",
                attrs: { name: "header-button", type: "button" },
                on: { click: _vm.toggleMenu },
              },
              [
                _c(
                  "span",
                  { staticClass: "cross", class: { cross_active: _vm.active } },
                  [_c("span", { staticClass: "cross__line" })]
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass: "overlay",
              class: { overlay_opened: _vm.active },
              on: { click: _vm.toggleMenu },
            }),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass: "header__menu",
                class: { header__menu_opened: _vm.active },
              },
              _vm._l(_vm.links, function (link) {
                return _c(
                  "li",
                  {
                    key: link.id,
                    staticClass: "header__menu-item",
                    on: { click: _vm.toggleMenu },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "header__menu-link",
                        attrs: { href: `#${link.url}` },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(link.anchor) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "logo", attrs: { href: "#about" } }, [
      _c("span", { staticClass: "logo__row logo__row_first" }, [
        _vm._v("Предприятие"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "logo__row logo__row_second" }, [
        _vm._v("3826"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }